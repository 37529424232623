import type { LinksFunction, MetaFunction } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { isApplicationError } from '@trustlayer/application-error';
import { Button, ConfigProvider, Result } from 'antd';
import reset from 'antd/dist/reset.css?url';
import { useEffect } from 'react';
import { TLtheme } from './theme.js';

export const links: LinksFunction = () => [
  {
    rel: 'stylesheet',
    href: reset,
  },
  {
    rel: 'stylesheet',
    href: '/antd.css',
  },
];

export const meta: MetaFunction = () => [
  {
    title: 'Trustlayer Portal',
  },
  {
    description: 'Requesting documents from TrustLayer',
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    const hasGtmScript = document.getElementById('gtm');

    if (!hasGtmScript) {
      const gtm = document.createElement('script');
      gtm.id = 'gtm';
      gtm.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NBKQS49T');`;
      document.head.prepend(gtm);
    }
  }, []);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-NBKQS49T"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
            title="Google Tag Manager"
          />
        </noscript>
        <ConfigProvider theme={TLtheme}>{children}</ConfigProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  const applicationError = isApplicationError(error);
  const responseError = isRouteErrorResponse(error);

  let message = 'Oops, something went wrong.';
  let status = 500;

  if (!applicationError && !responseError) {
    captureRemixErrorBoundaryError(error);
  }

  if (applicationError) {
    message = error.message;
    status = error.statusCode ?? 500;
  } else if (responseError) {
    message = error.data?.message ?? error.statusText;
    status = error.status;
  }

  message =
    status < 500
      ? message
      : 'Oops, something went wrong. Please try again or contact support.';

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Result
        status={status === 404 || status === 403 ? status : 500}
        title={statusCodeToTitle(status)}
        subTitle={message}
        extra={
          <Button
            type="primary"
            onClick={() =>
              typeof window !== 'undefined' ? window.location.reload() : null
            }
            size="large"
          >
            Reload
          </Button>
        }
      />
    </div>
  );
};

export default function App() {
  return <Outlet />;
}

function statusCodeToTitle(statusCode: number) {
  if (statusCode === 404) {
    return 'Page not found';
  }

  if (statusCode === 403) {
    return 'You are not authorized to access this page';
  }

  if (statusCode >= 400 && statusCode < 500) {
    return 'Invalid request';
  }

  return '500';
}
